<template>
    <div class="eCommerce-container">
        <div class="tab-top" v-if="method === 'stuTrain'">
            <el-breadcrumb separator=">" class="genera-breadcrumb" style="padding: 16px 0 0px">
                <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                <el-breadcrumb-item>答题详情</el-breadcrumb-item>
                <el-breadcrumb-item>客服信息收集</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="back-btn" @click="goBack" type="primary">返回</el-button>
        </div>
        <div class="tab-top" v-else>
            <el-breadcrumb separator=">" class="genera-breadcrumb" style="padding: 16px 0 0px">
                <el-breadcrumb-item style="font-size: 18px">查看客户信息</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button class="back-btn" @click="goBack" type="primary">返回</el-button>
        </div>
        <el-table :data="infoList" class="customTable" style="width: 100%; margin-top: 20px; margin-bottom: 20px; flex: 1" height="1%">
            <el-table-column prop="nick_name" label="客户名称" align="center"></el-table-column>
            <!-- <el-table-column prop="name" label="姓名" align="center"></el-table-column> -->
            <el-table-column prop="age" label="年龄" align="center"></el-table-column>
            <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
            <el-table-column prop="address" label="收货地址" align="center" min-width="150"></el-table-column>
            <!-- <el-table-column prop="buy_cycle" label="购买周期" align="center"></el-table-column> -->
            <el-table-column prop="avg_price" label="平均客单价" align="center"></el-table-column>
            <!-- <el-table-column v-else prop="avg_price" label="客单价" align="center"></el-table-column> -->
            <!-- <el-table-column v-if="type == 'stuAnswer'" prop="buy_num" label="购买次数" align="center"></el-table-column> -->
            <!-- <el-table-column prop="praise_rate" label="好评率" align="center"></el-table-column> -->
            <el-table-column prop="praise_rate" label="评价" align="center"></el-table-column>
            <el-table-column prop="stand_time" label="页面停留时间" align="center"></el-table-column>
            <el-table-column prop="change_num" label="订单改价次数" align="center"></el-table-column>
            <template slot="empty">
                <div class="null-data">
                    <div style="display: flex; flex-direction: column; align-items: center">
                        <img src="../../../../assets/images/student/goods-null-data.png" alt="" />
                        <span>暂无数据</span>
                    </div>
                </div>
            </template>
        </el-table>
    </div>
</template>

<script>
import { teacheropTrainCustomerStuAnswer, teacheropCustomerAnswer, teacheropCustomerStuAnswer } from "@/utils/apis.js";
export default {
    data() {
        return {
            method: this.$route.query.method || null,
            type: this.$route.query.type || null,
            infoList: [],
        };
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async getInfo() {
            let params = {};
            let res;
            if (this.type === "stuAnswer") {
                // 学生答案
                if (this.method === "stuTrain" || this.method === "teaTrain") {
                    // 学生训练、教师训练的学生答案
                    params.train_id = Number(this.$route.query.trainId);
                    res = await teacheropTrainCustomerStuAnswer(params);
                }
                if (this.method === "teaExam" || this.method === "teaExamTrain") {
                    // 教师端考试中心 学生答案
                    params.exam_id = this.$route.query.examId;
                    params.student_id = this.$route.query.studentId;
                    res = await teacheropCustomerStuAnswer(params);
                }
            }
            if (this.type === "correctAnswer") {
                // 教师端训练中心，教师端考试中心 正确答案
                params.id = this.$route.query.trainId;
                res = await teacheropCustomerAnswer(params);
            }
            this.infoList = res.data;
        },
    },
};
</script>

<style scoped lang="scss">
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.tab-top {
    position: relative;
    .back-btn {
        position: absolute;
        right: 0;
        top: 0;
    }
}
</style>
